<script setup>
const route = useRoute()
const { generateBreadcrumbs, generateMeta } = useMetaUtils()
const { getPageForRoute } = useApiPage()

const consentCookie = useCookie('consent')

const { data: page } = await useAsyncData(
  `page-${route?.path}`,
  getPageForRoute,
)

if (!page.value) {
  navigateTo({ name: 'not-found' })
}

useSeoMeta(generateMeta(page.value))

const config = useRuntimeConfig()

const shouldLoadHubSpot = computed(() => {
  return Boolean(
    consentCookie.value?.marketing &&
      page.value?.slug === 'voor-bedrijven' &&
      config.public.meta.hubspotScriptId,
  )
})

useHead(() => ({
  script: shouldLoadHubSpot.value
    ? [
        {
          src: `https://js-eu1.hs-scripts.com/${config.public.meta.hubspotScriptId}.js`,
          id: 'hs-script-loader',
          async: true,
          defer: true,
          body: true,
        },
      ]
    : [],
}))
</script>

<template>
  <BaseContent
    v-if="page"
    :content="page?.content"
    :breadcrumbs="generateBreadcrumbs(page)"
  />
</template>
